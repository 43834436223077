import React from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { PrimaryButton, InvertedButton } from '../components/Button';
import { applyUpdateJob, unapplyUpdateJob } from '../actions/job-applications';
import { fetchJobs, updateJob } from '../actions/jobs';
import ActionFeedback from '../components/ActionFeedback';
import withLoader from '../components/withLoader';
import withFetchError from '../components/withFetchError';
import SimpleConfirm from '../services/SimpleConfirm';
import withNoItems from '../components/withNoItems';
import JobArticle from '../components/JobArticle';
import { UserInterestStatus } from '../constants/Statuses';

class JobContainer extends React.Component {

  render() {
    const { job, updating, applied, approved } = this.props;
    
    const addressWithoutNumber = (address) => {
      const [trimmed] = (address?.match(/^[\D]*[^\s\d]/) ?? []);
      return trimmed;
    };

    const notes = job.workorder_lines.map(item => item.note).join('\n\n') || '';
    return (
      <View style={ styles.container }>
        <JobArticle
          workorderId={ job.workorder_number }
          title={ job.title }
          subtitle={ job.office.name }
          clientAddress={ job.client ? `${ addressWithoutNumber(job.client.address) }, ${ job.client.postal_code }, ${ job.client.city }` : '' }
          text={ job.workorder_lines[0].description }
          note={ notes }
          createdAt={ job.created_at }
          applied={ applied }
          approved={ approved }
        />
        {applied || approved ? (
          <View>
            <ActionFeedback
              title={ approved ? 'Tilldelad' : 'Anmälan skickad' }
              text={ approved ? `Du har fått uppdraget ${ job.title } i ${ job.office.name }, du kommer få din arbetsorder när uppdraget är fullständigt tillsatt` :`Du har sökt jobbet ${ job.title } i ${ job.office.name }. Lokalkontoret kommer att återkoppla till dig.` }
              success={ true }
            />
            {applied && <View style={ styles.unapplyActions }>
              <InvertedButton
                title={ updating ? 'Skickar...' : 'Avanmäl intresse' }
                onPress={ this._onUnapplyPress(job.id) }
                icon={ updating && <ActivityIndicator color={ '#fff' } /> }
              />
            </View>
            }
          </View>
        
        ) : (
          <View style={ styles.actions }>
            <PrimaryButton
              title={ updating ? 'Skickar...' : 'Anmäl intresse' }
              onPress={ this._onApplyPress(job.id) }
              icon={ updating && <ActivityIndicator color={ '#fff' } /> }
            />
          </View>
        )
        }
      </View>
    );
  }

  _onApplyPress = id => async () => {
    const { applyUpdateJob, job, updating, fetchJobs, updateJob } = this.props;

    if (updating) {
      return;
    }

    const confirmResponse = await SimpleConfirm({
      title: 'Anmäl intresse',
      message: `Vill du anmäla intresse för ${ job.title }?`,
      actionText: 'Anmäl',
      cancelText: 'Avbryt',
    });

    if (confirmResponse.result) {
      await applyUpdateJob(id);
      updateJob({ jobId: id, status: UserInterestStatus.APPLIED });
    }
  }

  _onUnapplyPress = id => async () => {
    const { unapplyUpdateJob, job, updating, fetchJobs, updateJob } = this.props;
  
    if (updating) {
      return;
    }
  
    const confirmResponse = await SimpleConfirm({
      title: 'Avanmäl intresse',
      message: `Vill du avanmäla intresse för ${ job.title }?`,
      actionText: 'Avanmäl',
      cancelText: 'Avbryt',
    });
  
    if (confirmResponse.result) {
      await unapplyUpdateJob(id);
      updateJob({ jobId: id, status: 0 });
    }
  }

}

const styles = StyleSheet.create({
  container: {
  },
  actions: {
    padding: 24,
  },
  unapplyActions: {
    paddingLeft: 24,
    paddingRight: 24,
  },
});

const mapState = (state, ownProps) => {
  const { jobs, jobApplications } = state;
  const job = jobs.items.find(m => m.id === ownProps.id);
  return {
    loading: jobs.fetching,
    fetchError: jobs.fetchError,
    noItems: !job && !jobs.fetching,
    updating: jobApplications.updating,
    applied: job && job.workorder_lines[0].user_interest_status === UserInterestStatus.APPLIED,
    approved: job && job.workorder_lines[0].user_interest_status === UserInterestStatus.APPROVED,
    job,
  };
};

const mapDispatch = {
  applyUpdateJob,
  unapplyUpdateJob,
  fetchJobs,
  updateJob,
};

export default compose(
  connect(mapState, mapDispatch),
  withNoItems({ text: 'Vappen kunde inte hitta uppdraget' }),
  withLoader,
  withFetchError
)(JobContainer);
